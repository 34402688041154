@font-face {
  font-family: 'Righteous';
  src: local('Righteous'), url('./fonts/Righteous-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}
@font-face {
  font-family: 'Noto Sans';
  src: local('Noto_Sans'), url('./fonts/Noto_Sans/NotoSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}
@font-face {
  font-family: 'Noto Sans';
  src: local('NotoSans-Bold'), url('./fonts/Noto_Sans/NotoSans-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
  font-display: fallback;
}
